import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";

const ChileChontal = () => {
  const { t } = useTranslation();

  const ingredients = t("ingredientsContent", { returnObjects: true });
  const preparation = t("preparationContent", { returnObjects: true });

  const half = Math.ceil(ingredients.length / 2);
  const firstHalf = ingredients.slice(0, half);
  const secondHalf = ingredients.slice(half);

  // Dividir los pasos en dos mitades
  const halfP = Math.ceil(preparation.length / 2);
  const firstHalfP = preparation.slice(0, halfP);
  const secondHalfP = preparation.slice(halfP);

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
        backgroundColor: "#fff",
      }}
    >
      <Seo
        title="¿Qué es el Chile Chontal?"
        description=" En el año 2023, el estado de Tabasco fue invitado al Primer
              Festival Internacional del Chile en Nogada realizado en el World
              Trade Center en Ciudad de México. La invitación llegó por parte
              del organizador del festival, el chef internacional Jorge Orozco,
              reconocido ampliamente por ser el Embajador de la Comida
              Prehispánica"
      />

      <Navbar />
      <Grid
        container
        justifyContent="center"
        spacing={12}
        marginBottom={10}
        mt={12}
      >
        <img
          src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2024/05/13/20240513151055-289bdc7a-xx.jpg"
          alt="chile chontal"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: 700,
            objectFit: "cover",
          }}
        />
        <Grid
          item
          position="absolute"
          zIndex={99}
          top={{ xs: 50, md: 250 }}
          margin="0 auto"
        >
          <Typography fontWeight="bold" fontSize={72} color="white">
            {t("title")}
          </Typography>
          <Typography color="white" fontSize={32} textAlign="center">
            {t("subtitle")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container maxWidth={1240} margin="0 auto" my={12} paddingX={2}>
        <Grid item container>
          {t("contentSubtitle", { returnObjects: true }).map(
            (itemContent, i) => (
              <Grid item xs={4} key={i}>
                <Typography
                  textAlign="center"
                  textTransform="uppercase"
                  marginBottom={2}
                >
                  {itemContent.title}
                </Typography>
                <Typography textAlign="center">{itemContent.value}</Typography>
              </Grid>
            )
          )}
        </Grid>

        {/* Nacimiento del chile chontal */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              {t("historyTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>{t("historyContent")}</Typography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <img
              src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2024/05/21/20240521110140-e5a669cb-xx.jpg"
              style={{ width: "100%", maxWidth: 260 }}
              alt="chef"
            />
          </Grid>
        </Grid>
        {/* Fin del nacimiento del chile chontal */}

        {/* Ingredientes */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              {t("ingredients")}
            </Typography>
          </Grid>
          {/* Primera columna */}
          <Grid item xs={12} md={6}>
            <ul>
              {firstHalf.map((ingredient, i) => (
                <li key={i}>{ingredient}</li>
              ))}
            </ul>
          </Grid>

          {/* Segunda columna */}
          <Grid item xs={12} md={6}>
            <ul>
              {secondHalf.map((ingredient, i) => (
                <li key={half + i}>{ingredient}</li> // La key aquí debe seguir siendo única
              ))}
            </ul>
          </Grid>
        </Grid>
        {/* Fin de Ingredientes */}

        {/* Preparación */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              {t("preparationTitle")}
            </Typography>
          </Grid>
          {/* Primera columna */}
          <Grid item xs={12} md={6}>
            {firstHalfP.map((step, i) => (
              <Grid item xs={12} py={1} key={i}>
                <Typography fontWeight="600">{step.step}</Typography>
                {step.subSteps.map((substep, j) => (
                  <Typography key={j} whiteSpace="pre-line">
                    {substep}
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>

          {/* Segunda columna */}
          <Grid item xs={12} md={6}>
            {secondHalfP.map((step, i) => (
              <Grid item xs={12} py={1} key={half + i}>
                <Typography fontWeight="600">{step.step}</Typography>
                {step.subSteps.map((substep, j) => (
                  <Typography key={j} whiteSpace="pre-line">
                    {substep}
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* Fin de Preparación */}
      </Grid>
      <Footer />
    </Container>
  );
};

export default ChileChontal;
export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["chile-chontal"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
